// const apiUrl = "http://192.168.208.203:3000/api/v1/customer/"
// const apiUrlV2 = "http://192.168.208.203:3000/api/v2/customer/"
// const authUrl = "http://192.168.208.203:3000/api/v1/"
// const webUrl = "http://192.168.208.203:3000/"

const apiUrl = "https://admin.burraa.com/api/v1/customer/"
const apiUrlV2 = "https://admin.burraa.com/api/v2/customer/"
const authUrl = "https://admin.burraa.com/api/v1/"
const webUrl = "https://admin.burraa.com/"

const googleMapApiKey = "AIzaSyBkFY7DxU93prAY-jdBvsb4kg8dfy855-Q"
const reverseGeocodeUrl = "https://maps.googleapis.com/maps/api/geocode/json"
const razorpayApiKey = "rzp_test_85cR8nH50LTE65"
const MEASUREMENT_ID = "G-TR5BPL14Z9";

const generateOtp = (phone) => {
  var digits = '0123456789';
  let OTP = '';
  for (let i = 0; i < 6; i++ ) {
    if(phone === '1234567890') {
      OTP = '123456'
    }else{
      OTP += digits[Math.floor(Math.random() * 10)];
    }
  }
  return OTP;
}


export default { apiUrl, authUrl, apiUrlV2, webUrl, generateOtp, googleMapApiKey, reverseGeocodeUrl, razorpayApiKey, MEASUREMENT_ID };
