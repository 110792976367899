import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './assets/css/vendors.css';
import './assets/css/main.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
// import { GoogleOAuthProvider } from '@react-oauth/google';

// import './assets/js/main.js';
// import './assets/js/vendors.js';
// import {Helmet} from "react-helmet";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <App />
)
// root.render(
//   <GoogleOAuthProvider clientId="938835658276-2v13d0jbmflflk6dc38a2e6drk34mpsq.apps.googleusercontent.com">
//     <React.StrictMode>
//       <App />
//     </React.StrictMode>
//   </GoogleOAuthProvider>
// );

reportWebVitals();
