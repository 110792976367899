import React, { createContext, useContext, useState } from 'react';

const HeaderContext = createContext();

export const HeaderProvider = ({ children }) => {
  const [headerValue, setHeaderValue] = useState(0);
  const [signedIn, setSignedIn] = useState(false);
  const [echo, setEcho] = useState('');

  const updateHeaderValue = (newValue) => {
    setHeaderValue(newValue);
  };

  const updateSignedInValue = (newValue) => {
    setSignedIn(newValue);
  };

  const updateEcho = (v) => {
    setEcho(v);
  };

  return (
    <HeaderContext.Provider value={{ 
      headerValue, updateHeaderValue, 
      signedIn, updateSignedInValue,
      echo, updateEcho 
    }}>
      {children}
    </HeaderContext.Provider>
  );
};

export const useHeaderContext = () => {
  return useContext(HeaderContext);
};
