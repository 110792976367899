import { lazy } from "react";

const Home = lazy(() => import("pages/Website/Home"));
const Activity = lazy(() => import("pages/Website/Activity"));
const Categories = lazy(() => import("pages/Website/Categories"));
const Blog = lazy(() => import("pages/Website/Blog"));
const BlogDetails = lazy(() => import("pages/Website/Blog/show"));
const Signup = lazy(() => import("pages/Website/Signup"));
const Contact = lazy(() => import("pages/Website/Contact"));
const AboutUs = lazy(() => import("pages/Website/AboutUs"));
const ActivityDetails = lazy(() => import("pages/Website/ActivityDetails"));
const Cart = lazy(() => import("pages/Website/Cart"));
const Events = lazy(() => import("pages/Website/Events"));

const Offers = lazy(() => import("pages/Website/Offers"));
const OfferDetails = lazy(() => import("pages/Website/Offers/show"));
const PrivacyPolicy = lazy(() => import("pages/Website/Policy"));
const TermsAndConditions = lazy(() => import("pages/Website/Terms"));
const RefundCancelation = lazy(() => import("pages/Website/RefundCancelation"));

const PaymentSuccess = lazy(() => import("pages/Website/PaymentSuccess"));

const AppRoutes = [
  {
    path: "/",
    component: <Home />,
  },
  {
    path: "/activities",
    component: <Activity />,
  },
  {
    path: "/categories",
    component: <Categories />,
  },
  {
    path: "/blogs",
    component: <Blog />,
  },
  {
    path: "/blogs/:category_name/:title",
    component: <BlogDetails />,
  },
  {
    path: "/signup",
    component: <Signup />,
  },
  {
    path: "/contact",
    component: <Contact />,
  },
  {
    path: "/about-us",
    component: <AboutUs />,
  },
  {
    path: "/activities/:title",
    component: <ActivityDetails />,
  },
  {
    path: "/cart",
    component: <Cart />,
  },
  {
    path: "/offers",
    component: <Offers />,
  },
  {
    path: "/offer-details/:title",
    component: <OfferDetails />,
  },
  {
    path: "/privacy-policy",
    component: <PrivacyPolicy />,
  },
  {
    path: "/terms-and-conditions",
    component: <TermsAndConditions />,
  },
  {
    path: "/refund-and-cancelation-policy",
    component: <RefundCancelation />,
  },
  {
    path: "/payment-success",
    component: <PaymentSuccess />,
  },
  // {
  //   path: "/activities/rangover/events",
  //   component: <Events />,
  // },
]

export default AppRoutes;
