export const findUser = () => {
  const value = localStorage.getItem('user')
  const userId = localStorage.getItem('userId')

  if (!!value) {
    return JSON.parse(value)
  } else if (!!userId) {
    return { id: userId, userType: 'temp', auth_token: null }
  } else {
    return undefined;
  }
};