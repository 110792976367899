import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faFacebook, faYoutube } from '@fortawesome/free-brands-svg-icons';

function Footer() {

  function scrollToTop() {
    window.scroll({top: 0, left: 0, behavior: 'smooth' })
  }

  return (
    <footer className="footer -type-2 bg-light_blue">
      <div className="container">
        <div className="pt-40 pb-40">
          <div className="row y-gap-40 justify-between xl:justify-start">
            <div className="col-xl-4 col-lg-6">

              <div className="row x-gap-20 y-gap-15">
                <div className="col-12">
                  <h5 className="text-16 fw-500">Your all-in-one travel app</h5>
                </div>

                <Link to={`https://apps.apple.com/in/app/burraa/id6476379271`} target="_blank" className="col-auto col-lg-6">
                  <div className="d-flex items-center px-20 py-10 rounded-4 bg-white">
                    <div className="icon-apple text-24 text-light-1"></div>
                    <div className="ml-20">
                      <div className="text-14 lh-14 text-light-1">Download from</div>
                      <div className="text-15 lh-14 fw-500 text-light-1">App Store</div>
                    </div>
                  </div>
                </Link>

                <Link to={`https://play.google.com/store/apps/details?id=com.burracustomer`} target="_blank" className="col-auto col-lg-6">
                  <div className="d-flex items-center px-20 py-10 rounded-4 bg-white">
                    <div className="icon-play-market text-24 text-light-1"></div>
                    <div className="ml-20">
                      <div className="text-14 lh-14 text-light-1">Download from</div>
                      <div className="text-15 lh-14 fw-500 text-light-1">Google Play</div>
                    </div>
                  </div>
                </Link>
              </div>

              
            </div>

            <div className="col-lg-6">
              <div className="row y-gap-30">

                <div className="col-lg-4 col-sm-6">
                  <div className="d-flex y-gap-10 flex-column">
                    <Link onClick={scrollToTop} to={'/about-us'}>About Us</Link>
                    <Link onClick={scrollToTop} to={'/contact'}>Contact</Link>
                    <Link onClick={scrollToTop} to={'/blogs'}>Blogs</Link>
                  </div>
                </div>

                <div className="col-lg-4 col-sm-6">
                  <div className="d-flex y-gap-10 flex-column">
                    
                    <Link onClick={scrollToTop} to={'/privacy-policy'}>Privacy Policy</Link>
                    <Link onClick={scrollToTop} to={'/terms-and-conditions'}>Terms of Use</Link>
                    <Link onClick={scrollToTop} to={'/refund-and-cancelation-policy'}>Refund and Cancelation Policy</Link>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>

        <div className="py-20 border-top-light">
          <div className="row justify-between items-center y-gap-10">
            <div className="col-auto">
              <div className="row x-gap-30 y-gap-10">
                <div className="col-auto">
                  <div className="d-flex items-center">
                    © 2024 Burraa LLC All rights reserved.
                  </div>
                </div>
              </div>
            </div>

            <div className="col-auto">
              <div className="row y-gap-10 items-center">
                <div className="col-auto">
                  <div className="d-flex items-center">
                    <Link to={`https://www.instagram.com/burraa__?utm_source=qr&igsh=Yjh2NWk0eWhncHZh`} target="_href" rel="noopener noreferrer" className="d-flex items-center text-14 fw-500 text-dark-1 mr-10">
                      <FontAwesomeIcon icon={faInstagram} className="pr-10 text-18 text-light-1" />
                    </Link>
                    <Link to={`https://www.facebook.com/profile.php?id=61557465966497&mibextid=ZbWKwL`} target="_href" rel="noopener noreferrer" className="d-flex items-center text-14 fw-500 text-dark-1 mr-10">
                      <FontAwesomeIcon icon={faFacebook} className="pr-10 text-18 text-light-1" />
                    </Link>
                    <Link to={`https://youtube.com/@Burraa_?si=oiCrsjxrhSXKfv69`} target="_href" rel="noopener noreferrer" className="d-flex items-center text-14 fw-500 text-dark-1 mr-10">
                      <FontAwesomeIcon icon={faYoutube} className="pr-10 text-18 text-light-1" />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer;