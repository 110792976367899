import React, { useState, useCallback, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Logo from 'assets/images/logo.png'
import avatar1 from "assets/images/misc/avatar-1.png"
import { useHeaderContext } from 'config/HeaderContext';
import { googleLogout } from '@react-oauth/google';
import Events from 'js/events'
import { Loader } from "components/Loaders"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPhone,
  faEnvelope,
  faCartShopping,
  faHouse,
  faPersonHiking,
  faBlog,
  faPercent,
  faCaretDown,
  faGamepad,
  faUserPlus
} from '@fortawesome/free-solid-svg-icons';
import { faFacebook, faInstagram, faYoutube } from '@fortawesome/free-brands-svg-icons';
import Api from 'config/Api';
import axios from 'axios';
import { findUser } from '../../../utils/userUtil';


const Header = () => {

  const [user, setUser] = useState({})
  const [name, setName] = useState('')
  const [loading, setLoading] = useState(false)
  const { headerValue, updateHeaderValue, signedIn, echo } = useHeaderContext();

  const navigate = useNavigate();

  const getUser = () => {
    const user = findUser();

    if (!!user) {
      const firstName = user?.first_name ? user.first_name : ''
      const lastName = user?.last_name ? user.last_name : ''
      const fullName = firstName + ' ' + lastName
      setUser(user)
      setName(fullName)
      _getCartItems(user.id)
    }
  }

  const uniqueItems = useCallback((data) => {
    const cartItems = data.reduce((acc, currentItem) => {
      const existingItem = acc.find((item) => item.package_type_id === currentItem.package_type_id && item.selected_date === currentItem.selected_date);

      if (existingItem) {
        existingItem.quantity += 1;
      } else {
        acc.push({ ...currentItem, quantity: 1 });
      }
      return acc;
    }, []);
    updateHeaderValue(cartItems.length)
  }, [updateHeaderValue])


  const _getCartItems = useCallback(async (userId) => {
    try {
      const resp = await axios.get(Api.apiUrlV2 + `carts/items`, { 
        params: { userId }
      })
      if (resp?.data) {
        uniqueItems(resp.data)
      }
    } catch (err) {
      console.log("Header: Cart Error", err)
    }
  }, [uniqueItems]);

  const logout = () => {
    setLoading(true)
    googleLogout();
    localStorage.removeItem('user');
    setTimeout(() => {
      navigate('/signup');
      window.location.reload();
    }, 1000);
  }

  function currentPage(pathname) {
    if (pathname === window.location.pathname) {
      return 'bg-blue-1-05'
    }
  }

  const toggleMenu = () => {
    Events.init()
    setTimeout(() => {
      Events.init()
    }, 1000);
  }

  useEffect(() => {
    Events.destroyHeaderMenu();
    Events.init()
    getUser();
  }, [signedIn, updateHeaderValue, echo]);

  return (
    <header data-add-bg="" className="header bg-white shadow-3 js-header px-50 sm:px-10" data-x="header" data-x-toggle="is-menu-opened">
        <Link to="/" className="header-logo" data-x="header-logo" data-x-toggle="is-logo-dark">
          <img src={Logo} style={{ width: 145, height: 45 }} alt="" />
        </Link>
      <div data-anim="fade" className="header__container px-30 sm:px-20">
        <div className="top-row pb-0">
          <div className="top-contact-info d-flex justify-end items-center">
            {/* Phone link */}
            <a href="tel:08484801861" className="d-flex items-center text-dark-1 mr-20">
              <FontAwesomeIcon icon={faPhone} className="pr-5" />+91 8484801861
            </a>

            {/* Email link */}
            <a href="mailto:support@burraa.com" className="d-flex items-center text-dark-1 mr-20">
              <FontAwesomeIcon icon={faEnvelope} className="pr-5" />support@burraa.com
            </a>
            
            {/* Instagram link */}
            <a href="https://www.instagram.com/burraa__/" target="_blank" rel="noopener noreferrer" className="d-flex items-center text-dark-1 mr-20">
              <FontAwesomeIcon icon={faInstagram} className="pr-5" />
            </a>

            {/* Facebook link */}
            <a href="https://www.facebook.com/profile.php?id=61557465966497&mibextid=ZbWKwL" target="_blank" rel="noopener noreferrer" className="d-flex items-center text-dark-1 mr-20">
              <FontAwesomeIcon icon={faFacebook} className="pr-5" />
            </a>

            {/* YouTube link */}
            <a href="https://www.youtube.com/@Burraa_" target="_blank" rel="noopener noreferrer" className="d-flex items-center text-dark-1">
              <FontAwesomeIcon icon={faYoutube} className="pr-5" />
            </a>
          </div>
        </div>

        <div className=" bottom-row row justify-between items-center">

          {loading && <Loader/>}
          <div className="col-auto">
            
            <div className="d-flex items-center">

              <div className="header-menu " data-x="mobile-menu" data-x-toggle="is-menu-active">
                <div className="mobile-overlay"></div>

                <div className="header-menu__content">
                  <div className="mobile-bg js-mobile-bg"></div>

                  <div className="menu js-navList ml-30">
                    <ul className="menu__nav text-dark-1 -is-active">

                      <li>
                        <Link to="/" onClick={() => Events.destroyHeaderMenu()} className={`button rounded-100 px-20 fw-400 text-14 h-50 text-dark-1 ${currentPage('/')}`}><FontAwesomeIcon icon={faHouse} className="pr-10 text-18 text-light-1" /> Home
                        </Link>
                      </li>

                      <li>
                        <Link to="/categories" onClick={() => Events.destroyHeaderMenu()} className={`button rounded-100 px-20 fw-400 text-14 h-50 text-dark-1 ${currentPage('/categories')}`}><FontAwesomeIcon icon={faGamepad} className="pr-10 text-20 text-light-1" />Categories
                        </Link>
                      </li>

                      <li>
                        <Link to="/activities" onClick={() => Events.destroyHeaderMenu()} className={`button rounded-100 px-20 fw-400 text-14 h-50 text-dark-1 ${currentPage('/activities')}`}><FontAwesomeIcon icon={faPersonHiking} className="pr-10 text-20 text-light-1" />Activities
                        </Link>
                      </li>

                      <li>
                        <Link to="/blogs" onClick={() => Events.destroyHeaderMenu()} className={`button rounded-100 px-20 fw-400 text-14 h-50 text-dark-1 ${currentPage('/blogs')}`}><FontAwesomeIcon icon={faBlog} className="pr-10 text-20 text-light-1" />Blogs
                        </Link>
                      </li>

                      <li>
                        <Link to="/offers" onClick={() => Events.destroyHeaderMenu()} className={`button rounded-100 px-20 fw-400 text-14 h-50 text-dark-1 ${currentPage('/offers')}`}><FontAwesomeIcon icon={faPercent} className="pr-10 text-20 text-light-1" />Offers
                        </Link>
                      </li>
                      <li className='mobile-only'>
                        <Link to="/signup" onClick={() => Events.destroyHeaderMenu()} className={`button rounded-100 px-20 fw-400 text-14 h-50 text-dark-1 ${currentPage('/signup')}`}><FontAwesomeIcon icon={faUserPlus} className="pr-10 text-20 text-light-1" />Sign In
                        / Register</Link>
                      </li>
                    </ul>
                  </div>

                  <div className="mobile-footer px-20 py-20 border-top-light js-mobile-footer">
                  </div>
                </div>
              </div>

            </div>
          </div>


          <div className="col-auto">
            <div className="d-flex items-center">
            <ul className="menu__nav text-dark-1 -is-active">
              <li>
                <Link to={'/cart'} onClick={() => Events.destroyHeaderMenu()} className={`button rounded-100 px-5 fw-400 text-14 h-50 text-dark-1 ${currentPage('/cart')}`}>
                  <FontAwesomeIcon icon={faCartShopping} className="pl-10 text-18 text-light-1" />
                  {headerValue > 0 && <span className="badge">{headerValue}</span>}
                </Link>
              </li>
            </ul>


            {user?.id && !user?.userType &&
            <div className="header-menu " data-x="mobile-menu" data-x-toggle="is-menu-active">
                <div className="mobile-overlay"></div>

                <div className="header-menu__content">
                  <div className="mobile-bg js-mobile-bg"></div>

                  <div className="menu js-navList">
                    <ul className="menu__nav text-dark-1 -is-active">

                      <li className="menu-item-has-children">
                        <Link data-barba to="#">
                          <span className="mr-10 fw-400 text-14 text-dark-1">{name ? name : user.phone || user.email}</span>
                          <FontAwesomeIcon icon={faCaretDown} className="text-14 text-light-1" />
                        </Link>

                        <ul className="subnav">
                          <li className="subnav__backBtn js-nav-list-back">
                            <Link to="#"><FontAwesomeIcon icon={faCaretDown} className="text-14 text-dark-1" /> {name ? name : user.phone || user.email}</Link>
                          </li>

                          <li><Link to="/db-booking">Bookings</Link></li>

                          <li><Link to="/db-wishlist">Wishlist</Link></li>

                          <li><Link to="/db-profile">Edit Profile</Link></li>

                          <li><Link to="/db-settings">Settings</Link></li>

                          <li><Link onClick={logout}>Logout</Link></li>

                        </ul>

                      </li>
                    </ul>
                  </div>

                  <div className="mobile-footer px-20 py-20 border-top-light js-mobile-footer">
                  </div>
                </div>
              </div>
              }

              {user?.id && !user?.userType ?
                <div className="pl-15">
                  <Link to={'/db-booking'} className="d-flex items-center text-inherit text-22 rounded-100 border-light">
                    <img src={user.profileImg?.uri ? user.profileImg.uri : avatar1} alt="" className="size-40 rounded-100 object-cover" />
                  </Link>
                </div>
                :
                <div className="d-flex items-center ml-20 is-menu-opened-hide desktop-only">
                    <Link to="/signup" className="button -outline-blue-1 rounded-100 px-30 fw-400 text-14 h-50 text-blue-1 ml-20">Sign In / Register</Link>
                </div>
              }

              <div onClick={toggleMenu} className="d-none xl:d-flex x-gap-20 items-center pl-30" data-x="header-mobile-icons"
                data-x-toggle="text-white">
                {/*<div><Link to="#" className="d-flex items-center icon-user text-inherit text-22"></Link></div>*/}
                <div><button className="d-flex items-center icon-menu text-dark-1 text-20"
                  data-x-click="html, header, header-logo, header-mobile-icons, mobile-menu"></button></div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </header >
  )
}

export default Header;