// src/components/ContactButtons.js

import React from 'react';
import './PhoneCallButton.css'; // Ensure to create this CSS file

const ContactButtons = () => {
  const phoneNumber = '08484801861'; // Replace with your phone number
  const whatsappNumber = '918484801861'; // Replace with your WhatsApp number

  return (
    <div className="contact-buttons">
      {/* Phone Call Button */}
      <a href={`tel:${phoneNumber}`} className="phonecall-button" aria-label="Call Us">
        <i className="fas fa-phone-alt"></i> {/* Font Awesome phone icon */}
      </a>

      {/* WhatsApp Button */}
      <a
        href={`https://wa.me/${whatsappNumber}?text=Hello! I would like to inquire about- `}
        className="whatsapp-button"
        target="_blank"
        rel="noopener noreferrer"
        aria-label="Chat on WhatsApp"
      >
        <i className="fab fa-whatsapp"></i> {/* Font Awesome WhatsApp icon */}
      </a>
    </div>
  );
};

export default ContactButtons;
